/* global fbq */
/* global branch */

import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import toastr from 'toastr';
import { Input, Modal, Button, Form } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { postRequest } from "../../api/http";
import LoginApi from "../../api/LoginApi";
import { ReactComponent as Logo } from "../../assets/images/LogoWith.svg";
import "./Login.scss";
import { logEvent } from "@firebase/analytics";
import { firebaseAnalytics } from "./firebase";
function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [isEnquireModalVisible, setIsEnquireModalVisible] = useState(false);
  const [enquireLoading, setEnquireLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
const url =process.env.REACT_APP_STUDENT_BASE_URL
  const handleChange = (e) => {
    const { name, value } = e.target; 
    if (name === "email") {
      setEmail(value);
    }
    if (name === "password") {
      setPassword(value);
    }
  };
 
  const trackEvent = (eventName) =>{
    if (window.fbq) {
      fbq("trackCustom", eventName);
    }
    logEvent(firebaseAnalytics, eventName);

  
  }
  useEffect(() => {
    trackEvent("LOGIN_PAGE_VIEW")
  }, [])
  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitted(true);
    if (!(email && password)) {
      return;
    }
    setLoading(true);
    try {
      const response = await postRequest(LoginApi.login(), { email, password });
      localStorage.setItem("token", response.accessToken);
      localStorage.setItem("refreshToken", response.refreshToken);
      trackEvent("SIGNUP_BUTTON_CLICK")
      if(response.role==="EMPLOYER"){
        console.log(response.role)
      }else{
        toastr.error("Invalid login")
      }
      if (location?.state?.from === "/forgetPassword") navigate("/");
      else navigate(location?.state?.from || "/");
    } catch (error) {
      console.log('error', error);
      if(error !== undefined ){
      if (error.status === 500) {
        toastr.error(error.error);
      } else {
        toastr.error(error.message);
      }
      setError(error.message);
      setLoading(false);
    }else{
      setLoading(false);
      toastr.error('Please check your internet connection');
      window.location.reload()
    }
  }
  };
// Show the Enquire Now modal
const showEnquireModal = () => {
  setIsEnquireModalVisible(true);
};

// Handle Enquire Now modal close
const handleEnquireCancel = () => {
  setIsEnquireModalVisible(false);
};

// Handle Enquire Now form submission
const handleEnquireSubmit = async (values) => {
  const { enquireEmail, phoneNumber } = values;
  setEnquireLoading(true);

  try {
    // Trigger events for Meta Pixel, Branch, and Firebase
    if (window.fbq) {
      fbq("trackCustom", "Enquire_Now_Submitted", { email: enquireEmail, phoneNumber });
    }
    logEvent(firebaseAnalytics, "enquire_now_submitted", { email: enquireEmail, phoneNumber });
    toastr.success("Your enquiry has been submitted!");
    setIsEnquireModalVisible(false);
  } catch (error) {
    toastr.error("Failed to submit your enquiry");
  } finally {
    setEnquireLoading(false);
  }
};
  function ImageLogo() {
    return (
      <Logo width={200} height={38} />
    );
  }

  return (
    <div className="loginPage">
      <div className="loginContainer">
        <div className="loginRow">
          <div className="loginCol loginBg"></div>
          <div className="loginCol loginForm">
            <div className="loginLogo">
              <Link to="/login"><ImageLogo /></Link>
            </div>
            <div className="loginFormBox">
              <div className="loginRoleBox">
                <div className="loginRoleCol">Hirer Login</div>
                <div className="loginRoleCol">
                  <a href={url}>Student login</a></div>
                <div className="loginRoleCol">
                  <a href="https://timbukdo.sp-seller.webkul.com/index.php?p=login">Merchant login</a></div>  
              </div>
              <form name="form" action="">
                <div
                  className={
                    "form-group" + (submitted && !email ? " has-error" : "")
                  }
                >
                  <Input
                    placeholder="Email"
                    className="form-control login-email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                  />
                  {submitted && !email && (
                    <div className="help-block">Email is required</div>
                  )}
                </div>
                <div
                  className={
                    "form-group" + (submitted && !password ? " has-error" : "")
                  }
                >
                  <Input.Password
                    placeholder="Password"
                    className="form-control"
                    name="password"
                    value={password}
                    onChange={handleChange}
                  />
                  {submitted && !password && (
                    <div className="help-block">Password is required</div>
                  )}
                </div>
                <div className="form-group submitRow">
                  <div className="loadingSpin">
                    {loading && <SyncOutlined spin />}
                  </div>
                  <input
                    type="submit"
                    disabled={loading}
                    className="loginBtn"
                    onClick={handleSubmit}
                    value="Login"
                  />
                </div>
                {error && (
                  <div className="redMandatory">
                    Please enter correct email & password
                  </div>
                )}
                <div>
                  <Link className="login-form-forgot" to="/forget-password">
                    Forgot Password?
                  </Link>
                </div>
              </form>
              <div className="registerBox">
                <p>Not Registered Yet?</p>
                <Link className="registerLink" to="/employer-registration" onClick={()=>trackEvent("REGISTER_NOW_BUTTON_CLICK")}>
                  Register here
                </Link>
                <Button type="link" className="registerLink"  onClick={showEnquireModal} style={{ marginLeft: "10px" }}>
                   <span style={{textDecoration:'underline'}}> Enquire Now</span>
                  </Button>
              </div>
              
              <div className="loginCopyRight">
                <p>Terms and Conditions, Copyrights @TimBuckDo</p>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Enquire Now"
        open={isEnquireModalVisible}
        onCancel={handleEnquireCancel}
        footer={null}
      >
        <Form layout="vertical" onFinish={handleEnquireSubmit} autoComplete="off">
          <Form.Item
            label="Email"
            name="enquireEmail"
            rules={[
              { required: true, message: "Please enter your email" },
              { type: "email", message: "Please enter a valid email" },
            ]}
          >
            <Input placeholder="Enter your email" />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[
              { required: true, message: "Please enter your phone number" },
              { pattern: /^[0-9]{10}$/, message: "Please enter a valid 10-digit phone number" },
            ]}
          >
            <Input placeholder="Enter your phone number" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={enquireLoading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default Login;
