import { getAnalytics } from "@firebase/analytics";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from 'firebase/messaging';
let firebaseConfig = {
    apiKey: process.env.REACT_APP_TIMBUKDO_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_TIMBUKDO_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_TIMBUKDO_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_TIMBUKDO_FIREBASE_STORAGE_BUCKET,
    messagingSenderId:
        process.env.REACT_APP_TIMBUKDO_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_TIMBUKDO_FIREBASE_APP_ID,
};
const app = initializeApp(firebaseConfig);
// const firebaseAuth = getAuth(app);
// export default firebaseAuth;
const messaging = getMessaging(app);

const firebaseAnalytics = getAnalytics(app);


export const requestPermission = () => {
    console.log('user permisson...')
    Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
            console.log('permssion granted')
            return getToken(messaging, {
                vapidKey: process.env.REACT_APP_TIMBUKDO_FIREBASE_VAPID_KEY
            }).then(currentToken => {
                if (currentToken) {
                    console.log('client Token', currentToken)
                    // alert(currentToken,'token')
                    
                } else {
                    console.log('failed to get regsitration tokem')
                }
            }).catch(err => {
                console.log('error occured', err)
            })
        } else {
            console.log('user permission denied')
        }
    })
}
requestPermission();

// export const onMessageListener=()=>{
//   new Promise(resolve=>{
//     onMessage(messaging,payload=>{
//       resolve(payload);
//     })
//   })


// }
export { messaging ,firebaseAnalytics };